import {
  SocialAuthServiceConfig,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { inject } from '@angular/core';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';

/**
 * Provider login by google
 * @returns
 */
export function provideSocialAuthServiceConfig(): SocialAuthServiceConfig {
  const coreAuthConfigService = inject(CoreAuthConfigService);
  return {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
          coreAuthConfigService.socicalNetworkAuthConfig.google?.clientId ?? '',
          coreAuthConfigService.socicalNetworkAuthConfig.google?.loginOptions
        ),
      },
    ],
    onError: (err) => {
      console.error(err);
    },
  };
}
